import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Core/Seo";
import { graphql } from "gatsby";
import Content from "../components/TextPage/Content";

const PrivacyPolicy = ({data}) => {
  const page = data.page;

  return (
    <Layout>
      <Seo title={page.title} metaTitle={page.metaTitle} metaDescription={page.metaDescription} path={`/${page.slug}`}/>
      <Content title={page.title} blocks={page.textBlocks}/>
    </Layout>
  )
}

export default PrivacyPolicy;

export const pageQuery = graphql`
    query PrivacyPolicyQuery {
        page: contentfulTextPage(slug: {eq: "privacy-policy"}) {
        title
        metaDescription
        metaTitle
        slug
        textBlocks {
          id
          content {
            raw
          }
        }
      }
    }
`
