import React from "react";
import styled from "styled-components";
import ContentContainer from "../Core/ContentContainer";
import { Col, Row } from "styled-bootstrap-grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Title from "../Core/Title";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";

const Section = styled.div`
  padding: 102px 0;
  
  @media(min-width: ${breakpoints.md}){
    padding: 125px 0;
  }
`;

const Block = styled.div`
  padding: 36px 0 0;
  
  p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.576px;
    ${fonts.RalewayThin};
    padding-bottom: 16px;
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  }
  
  a {
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  }
  
  h2 {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 1px;
    ${fonts.CabinBold};
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
    padding-bottom: 5px;
  }
  
  &:first-of-type {
    border-bottom: 1px solid;
    border-color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  }
  
  @media(min-width: ${breakpoints.md}){
    padding: 46px 0 0;
    
    p {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 20px;
    }
    
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media(min-width: ${breakpoints.lg}){
    padding: 40px 0 0;
  }
`;

const Content = ({ title, blocks }) => {

  return (
    <Section>
      <ContentContainer>
        <Row>
          <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
            <Title position={true}>{title}</Title>
          </Col>
          <Col md={8} mdOffset={2} lg={6} lgOffset={3}>
            {blocks.map((block, index) => {
              return (
                <Block key={index}>
                  {documentToReactComponents(JSON.parse(block.content.raw))}
                </Block>
              )
            })}
          </Col>
        </Row>
      </ContentContainer>
    </Section>
  )
}

export default Content;